/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#home {
  width: 38.13559322%;
  margin-top: 30px;
}
.desk {
  width: 92.1875%;
  max-width: 720px;
  max-width: 1100px;
}
.togglenavigation {
  top: 88px;
  transform: scale(1.6);
  transform-origin: 100% 0;
}
.main-nav {
  margin-top: 16px;
}
.mood {
  margin-top: 28px;
  height: 440px;
}
@media (min-width: 860px) {
  .mood {
    height: 460px;
  }
}
@media (min-width: 1000px) {
  .mood {
    height: 480px;
  }
}
@media (min-width: 1090px) {
  .mood {
    height: 544px;
  }
}
.cb-layout2 .mood {
  padding-bottom: 38.6187456%;
}
.cb-slide-previous,
.cb-slide-next {
  transform: scale(1.1);
}
a.cb-slide-previous {
  left: 19px;
}
a.cb-slide-next {
  right: 19px;
}
.cb-layout1 h1 {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.4;
  vertical-align: middle;
  padding: 0;
}
table.link {
  bottom: 18px;
}
.cb-index-all a {
  width: 14px;
  height: 14px;
}
.cb-layout2 #head {
  margin-top: 35px;
}
@media only screen and (max-width: 1199px) {
  div.south div.unit {
    margin: 0 14px;
    width: 296px;
  }
  .carousel-shadow {
    width: 296px;
  }
  #carousel {
    width: 944px;
  }
  #carousel--inner {
    width: 972px;
    margin-left: -14px;
  }
}
@media only screen and (max-width: 1023px) {
  div.south div.unit {
    margin: 0 11px;
    width: 262px;
  }
  .carousel-shadow {
    width: 262px;
  }
  #carousel {
    width: 830px;
  }
  #carousel--inner {
    width: 852px;
    margin-left: -11px;
  }
}
@media only screen and (max-width: 900px) {
  div.south div.unit {
    margin: 0 10px;
    width: 220px;
  }
  .carousel-shadow {
    width: 220px;
  }
  #carousel {
    width: 700px;
  }
  #carousel--inner {
    width: 720px;
    margin-left: -10px;
  }
}
@media only screen and (max-width: 1199px) {
  #carousel-links .arrow {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    background-size: 100% auto;
  }
  #view div.south div.head {
    bottom: 15px;
  }
}
.hr {
  margin-top: 23px;
  margin-bottom: 23px;
}
.popover-toggle {
  width: 60px;
  height: 60px;
  margin-left: -30px;
  top: 54%;
}
.popover-inner {
  width: 270px;
  left: 50%;
  margin-left: -205px;
  padding: 30px 70px 70px;
}
.footer-nav {
  display: block;
}
.cb-goto-top {
  display: none;
}
.krengerglas {
  margin-top: 2em;
}
.main-nav .menu {
  font-size: 25px;
  font-size: 2.5rem;
  padding: 0.95em 4.51977401% 1.15em;
}
@media (max-width: 1200px) {
  .main-nav > .navi > .path > .path {
    padding-bottom: 0.3em;
  }
  .main-nav > .navi > .path,
  .main-nav > .navi > .cb-toggle-target-active {
    padding-bottom: 21px;
  }
}
.main-nav .item > .navi .menu {
  letter-spacing: -0.02em;
  padding: 0.16em 9.03954802%;
}
.footer-nav {
  margin-top: 32px;
  *zoom: 1;
}
.footer-nav:before,
.footer-nav:after {
  display: table;
  content: '';
}
.footer-nav:after {
  clear: both;
}
.footer-nav > .navi > .item {
  width: 20%;
}
.footer-nav .menu {
  font-size: 13px;
  font-size: 1.3rem;
  padding: 0.2em 10px 0.2em 0;
  font-weight: normal;
  font-family: 'RobotoBold';
}
.footer-nav .item > .navi .menu {
  font-family: 'RobotoLight', sans-serif;
  padding-top: 0.15em;
  padding-bottom: 0.15em;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 2.03252033%;
  margin-left: 2.03252033%;
  width: 95.93495935%;
}
.area h2,
.area .foot {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
}
.area .part,
.area > .grid table {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
  width: 96.61016949%;
}
.area > .slim {
  width: 45.93495935%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.53982301%;
  margin-left: 3.53982301%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92.92035398%;
}
.area > .slim .tiny {
  width: 92.92035398%;
}
.cb-layout2 .base {
  width: 100%;
}
.cb-layout2 .base > .unit {
  margin-right: 2.03252033%;
  margin-left: 2.03252033%;
  width: 95.93495935%;
}
.cb-layout2 .base h2,
.cb-layout2 .base .foot {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
}
.cb-layout2 .base .part,
.cb-layout2 .base > .grid table {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
  width: 96.61016949%;
}
.cb-layout2 .base .tiny {
  width: 29.94350282%;
}
.cb-layout2 .base > .slim {
  width: 29.26829268%;
}
.cb-layout2 .base > .slim h2,
.cb-layout2 .base > .slim .foot,
.cb-layout2 .base > .slim .part,
.cb-layout2 .base > .slim.grid table {
  margin-right: 5.55555556%;
  margin-left: 5.55555556%;
}
.cb-layout2 .base > .slim .part,
.cb-layout2 .base > .slim.grid table {
  width: 88.88888889%;
}
.cb-layout2 .base > .slim .tiny {
  width: 88.88888889%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 2.03252033%;
  margin-left: 2.03252033%;
  width: 95.93495935%;
}
.south h2,
.south .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.south .part,
.south > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south > .slim {
  width: 29.26829268%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 100%;
}
.south > .slim .tiny {
  width: 100%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main > .unit {
  width: 45.93495935%;
}
.area,
.cb-layout2 .base {
  margin-left: -2.11864407%;
  width: 104.23728814%;
}
.main {
  margin-top: 30px;
}
.cb-layout2 .main {
  margin-top: 15px;
}
.unit {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
.cb-layout2 h1,
.unit h2,
.unit h3 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.25;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */